import { Issue } from '@gain/rpc/cms-model'
import { get, toPath } from 'lodash'
import isEqual from 'lodash/isEqual'
import { useFormState } from 'react-hook-form'

import { useInputFormContext } from './input-form-hooks'

interface IssueStatus {
  hasErrors: boolean
  hasWarnings: boolean
  message: string
}

function getIssueStatus(issues: Issue[]): IssueStatus {
  const hasErrors = issues.some((issue) => issue.type === 'error')
  const hasWarnings = issues.some((issue) => issue.type === 'warning')

  // If there are errors, only include errors in the message, otherwise include
  // the warnings
  const type = hasErrors ? 'error' : hasWarnings ? 'warning' : undefined
  const message = issues
    .filter((issue) => issue.type === type)
    .map((issue) => issue.message)
    .filter(Boolean)
    .join('. ')

  return {
    hasErrors,
    hasWarnings,
    message,
  }
}

/**
 * Returns if there are errors and/or warnings for the given form path and
 * builds the message that can be shown to the users.
 */
export function useInputFieldIssues(...paths: string[]): IssueStatus {
  // Get validation issues
  const inputForm = useInputFormContext()

  // Convert form errors to issues
  const { errors } = useFormState()

  const formIssues = paths.reduce((acc, path) => {
    const error = get(errors, path)

    if (error) {
      acc.push({
        type: 'error',
        message: error?.message?.toString() ?? '',
        path: path,
      })
    }

    return acc
  }, new Array<Issue>())

  // Merge form and validation issues and normalize the path
  const formAndValidationIssues = inputForm.issues.concat(formIssues).reduce((acc, issue) => {
    // Normalize the path to a dot separated format. (e.g. a[1].c > a.1.c)
    const normalizedPath = toPath(issue.path).join('.')
    const newIssue = {
      ...issue,
      path: normalizedPath,
    }

    const isPartialOrFullPathMatch =
      paths.includes(normalizedPath) || paths.some((path) => normalizedPath.startsWith(`${path}.`))
    const isDuplicate = acc.some((existingIssue) => isEqual(existingIssue, newIssue))

    // Allow a full match on the path, or a prefix match (e.g. `urls` will match
    // `urls.1.url`.
    if (isPartialOrFullPathMatch && !isDuplicate) {
      acc.push(newIssue)
    }

    return acc
  }, new Array<Issue>())

  return getIssueStatus(formAndValidationIssues)
}
